







import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class LocaleChanger extends Vue {
   switchLocale(locale: string) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
        const to = this.$router.resolve({ params: {locale} }) 
        this.$router.push(to.location) 
      }
    }
}
