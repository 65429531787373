import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import App from '../App.vue'
import i18n from '../i18n'

Vue.use(VueRouter)

var load = (component: string)=> {
  // '@' is aliased to src/components
  return () => import(`../views/${component}.vue`)
}

const routes = [
{
  path: '/:locale(nl|en)?',
  component: App,
  beforeEnter: (to: any, from:any, next:any) => { 
    let locale = to.params.locale; 
    if(!locale){
      locale = 'nl'
      return next('nl')
    }
    if (i18n.locale !== locale) { 
      i18n.locale = locale;
    }
    return next() 
  },
  children: [
    {
      path: '',
      name: 'home',
      component:  () => import('../views/Home.vue'),
      meta: {
        description: i18n.t('nav-services-meta')
      }
    },
    {
      path: 'about',
      name: 'about',
      component: load("About"),
      meta: {
        title: 'Mantares | ' + i18n.t('nav-about') ,
        description: i18n.t('nav-about-meta')
      }
    },
    {
      path: 'services',
      name: 'services',
      component: load("Services"),
      meta: {
        title: 'Mantares | ' + i18n.t('nav-services-title'),
        description: i18n.t('nav-services-meta')
      }
    },
    {
      path: 'products',
      name: 'products',
      component: load("Products"),
      meta: {
        title: 'Mantares | ' + i18n.t('nav-products-title') ,
        description: i18n.t('nav-products-meta')
      }
    },
    {
      path: 'contact',
      name: 'contact',
      component: load("Contact"),
      meta: {
        title: 'Mantares | ' + i18n.t('nav-contact') ,
        description: i18n.t('nav-contact-meta')
        
      }
    },

    { 
      path: '*',
      redirect() {
        return "nl";
      }
    }
  ]
},
  
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes ,
  scrollBehavior: (to) => {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
  }
})

router.beforeEach((to, from, next) => {

  document.title = to.meta.title || 'Mantares'
  document.querySelector('meta[name="description"]')!.setAttribute("content", to.meta.description || 'Mantares' );


  setTimeout(() => {
    if(!to.hash){
      window.scrollTo(0, 0);
    }
  });
  
  // More code ...
  next();
});

export default router
