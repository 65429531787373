























































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import LocaleChanger from "./components/LocaleChange.vue";



@Component({components: { LocaleChanger }})
export default class AppComponent extends Vue {


   currentRouteName() {
    return this.$route.name;
   }


}
