import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { faCalculator, faChalkboardTeacher, faCheckDouble, faCodeBranch, faEnvelope, faFileSignature, faLocationArrow, faPaperclip, faPlay, faRoute, faTruck } from "@fortawesome/free-solid-svg-icons";

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import i18n from './i18n'

import {
  faLinkedin,
  faTwitter,
  faGithub,
  faEdge,
} from "@fortawesome/free-brands-svg-icons";

import {
  faBullhorn,
  faBlog,
  faFileInvoice,
  faShoppingCart,
  faCalendarDay,
  faGlobe,
  faSlidersH,
  faTags,
  faEnvelopeOpen,
  faExternalLinkSquareAlt,
} from "@fortawesome/free-solid-svg-icons"

library.add(
  faBullhorn,
  faLinkedin,
  faTwitter,
  faEnvelope,
  faGithub,
  faBlog,
  faFileInvoice,
  faShoppingCart,
  faCalendarDay,
  faGlobe,
  faSlidersH,
  faChalkboardTeacher,
  faTags,
  faEnvelopeOpen,
  faFileSignature,
  faCodeBranch,
  faCalculator,
  faTruck,
  faCheckDouble,
  faRoute,
  faPaperclip,
  faEdge, 
  faPlay,
  faExternalLinkSquareAlt
);

Vue.config.productionTip = false
Vue.use(BootstrapVue);


Vue.component('font-awesome-icon', FontAwesomeIcon)

new Vue({
  router,
  i18n,
  render: h => h('router-view')
}).$mount('#app')

if (module.hot) {
  module.hot.accept(['./locales/nl.json', './locales/nl.json'], ()  => {
    i18n.setLocaleMessage('nl', require('./locales/nl.json').default)
    i18n.setLocaleMessage('en', require('./locales/en.json').default)
  });
}

